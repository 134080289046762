import api from './index'
import { axios } from '@/utils/request'

// 获取可替换变量
export function systemActiveLog(parameter) {
    return axios({
        url: api.SystemActiveLog,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取可替换变量
export function systemAdminLog(parameter) {
    return axios({
        url: api.SystemAdminLog,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
