<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @submit="handleSubmit" :form="form">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="日期">
              <a-date-picker style="width: 100%" format="YYYY-MM-DD" :disabled-date="disabledDate" v-model="queryDate"
                showToday placeholder="Select Time" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="用户类型">
              <a-select placeholder="请选择" v-decorator="[
                'user',
                {
                  initialValue: queryParam.user
                }
              ]">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="admin">管理员</a-select-option>
                <a-select-option value="user">客户</a-select-option>
                <a-select-option value="teacher">教员</a-select-option>
                <a-select-option value="system">系统</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button htmlType="submit" :loading="submitLoading" type="primary">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
              <a @click="toggleAdvanced" style="margin-left: 8px">
                {{ advanced ? '收起' : '展开' }}
                <a-icon :type="advanced ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
          <template v-if="advanced">
            <a-col :md="8" :sm="24">
              <a-form-item label="描述">
                <a-input v-decorator="[
                  'description',
                  {
                    initialValue: queryParam.description
                  }
                ]" placeholder="描述" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="IP地址">
                <a-input v-decorator="[
                  'ipaddress',
                  {
                    initialValue: queryParam.ipaddress
                  }
                ]" placeholder="IP地址" />
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="data" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <span slot="create_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
    </a-table>
  </a-card>
</template>
<script>
import { DatePicker as ADatePicker } from 'ant-design-vue'
import { systemActiveLog } from '@/api/system'
import merge from 'webpack-merge'
import moment from 'moment'

const statusMap = {
  'Active': {
    status: 'success',
    text: '激活'
  },
  'Closed': {
    status: 'default',
    text: '关闭'
  },
  'Error': {
    status: 'error',
    text: '异常'
  }
}

const dateConfig = {
  rules: [{ type: 'object', required: true, message: 'Please select time!' }]
}

const columns = [
  {
    sorter: true,
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '用户类型',
    dataIndex: 'user'
  },
  {
    title: '用户ID',
    dataIndex: 'userid'
  },
  {
    title: '描述',
    dataIndex: 'description'
  },
  {
    title: 'IP',
    dataIndex: 'ipaddress'
  },
  {
    title: '时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' }
  }
]

export default {
  components: {
    ADatePicker
  },
  data() {
    return {
      description: '系统活动日志',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      dateConfig: dateConfig,
      // 高级搜索 展开/关闭
      advanced: false,
      // form
      form: this.$form.createForm(this),
      // 查询参数
      queryParam: {
        user: this.$route.query.user,
        description: this.$route.query.description,
        ipaddress: this.$route.query.ipaddress
      },
      dateFormat: 'YYYY-MM-DD',
      date: '',
      queryDate: null,
      data: [],
      pagination: {},
      loading: false,
      columns,
      submitLoading: false,
      tableLoading: false
    }
  },
  created() {
    this.date = this.$route.query.date
  },
  mounted() {
    const params = this.$route.query
    this.fetch(params)
  },
  watch: {
    date(val) {
      if (val) {
        this.queryDate = moment(val, this.dateFormat)
      }
    },
    queryDate(val) {
      console.log(val)
      if (val) {
        this.date = val.format('YYYY-MM-DD')
      } else {
        this.date = ''
      }
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  methods: {
    moment,
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf('day')
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      // 带上查询参数
      const queryParam = this.form.getFieldsValue()
      if (this.date) {
        queryParam.date = this.date
      }
      const params = {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        ...queryParam
      }
      this.fetch(params)
      this.$router.push({
        query: merge({}, params)
      })
    },
    fetch(params) {
      console.log(params)
      this.loading = true
      new Promise((resolve, reject) => {
        systemActiveLog(params).then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        console.log(data)
        const pagination = { ...this.pagination }
        this.loading = false
        this.data = data.list.data
        pagination.total = data.list.total
        pagination.pageSize = data.list.per_page
        pagination.current = data.list.current_page
        this.pagination = pagination
      })
    },
    resetQuery() {
      // 重置查询条件，并重新请求
      this.form.setFieldsValue({
        date: '',
        user: ''
      })
      if (this.advanced) {
        this.form.setFieldsValue({
          description: '',
          ipaddress: ''
        })
      }
      this.$router.push({
        query: merge({}, {})
      })
      this.submitLoading = true
      this.fetch()
      this.submitLoading = false
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleSubmit(e) {
      // 重置查询条件，并重新请求
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitLoading = true
          if (this.date) {
            values.date = this.date
          }
          this.fetch(values)
          this.submitLoading = false
          this.$router.push({
            query: merge({}, values)
          })
        }
      })
    },
    goEdit(record) {
      this.$router.push({ 'name': 'ClientsDetail', query: { id: record.id } })
    }
  }
}
</script>
